import styled from 'styled-components';

const PopupRequestCallStyle = styled.div`
	--titleMarginBottom: var(--sp10x);
	--sendRequestButtonMarginTop: var(--sp5x);

	--tagPadding: var(--sp3x) var(--sp5x);
	--tagMarginRight: var(--sp4x);
	--tagsWrapMarginTopBottom: var(--sp4x) 0 var(--sp10x) 0;
	--inputDistance: var(--sp8x);
	&.reserve {
		.title {
			margin-bottom: unset;
		}
	}

	.reserve-info-wrap {
		display: flex;
		justify-content: center;
		margin: var(--tagsWrapMarginTopBottom);

		.tag {
			background-color: var(--background);
			padding: var(--tagPadding);
			width: fit-content;
			border-radius: var(--sp1x);
			margin-right: var(--tagMarginRight);

			&.apartment {
				margin-right: unset;
			}
		}
	}

	.submit-and-success-wrap {
		margin-top: var(--sendRequestButtonMarginTop);

		display: flex;
		align-items: center;
		justify-content: flex-end;

		.send-request-button {
			margin-left: auto;
			width: fit-content;
		}

		&.success {
			justify-content: space-between;
		}
	}

	.ant-input {
		background-color: transparent !important;
	}

	.title {
		text-align: center;
		margin-bottom: var(--titleMarginBottom);
	}
	.everyInput {
		padding-bottom: var(--inputDistance);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--titleMarginBottom: var(--sp10x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp4x);
		--tagMarginRight: var(--sp3x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp8x) 0;
		--inputDistance: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarginBottom: var(--sp6x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp6x) 0;
		--inputDistance: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarginBottom: var(--sp6x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp6x) 0;
		--inputDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--titleMarginBottom: var(--sp5x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp3x);
		--tagMarginRight: var(--sp2x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
		--inputDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleMarginBottom: var(--sp4x);
		--sendRequestButtonMarginTop: var(--sp4x);

		--tagPadding: var(--sp2x) var(--sp2-5x);
		--tagMarginRight: var(--sp1-5x);
		--tagsWrapMarginTopBottom: var(--sp3x) 0 var(--sp4x) 0;
		--inputDistance: var(--sp4x);
	}
`;

export default PopupRequestCallStyle;
