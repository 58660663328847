import styled from 'styled-components';

const FormInputStyle = styled.div`
	--inputPadTB: var(--sp3x);
	--textareaHeight: var(--sp41x);
	--borderSize: 2px;

	input,
	textarea {
		padding-bottom: var(--inputPadTB);
		width: 100%;
		font-size: var(--h6);
		color: var(--black1000);
		transition: unset;
		border-radius: 0;
		border: none;
		border-bottom: var(--borderSize) solid var(--black1000);
		font-family: var(--montserrat);
		font-weight: 400;
		transition: var(--trTime) ease-out;

		&::placeholder {
			font-size: var(--h6);
			color: var(--black1000);
			transition: var(--trTime) ease-out;
		}

		@media (hover: hover) {
			&:hover {
				color: var(--green1000);

				&::placeholder {
					color: var(--green1000);
				}

				border-bottom: var(--borderSize) solid var(--green1000);
			}
		}

		&:focus {
			color: var(--black1000);

			&::placeholder {
				color: var(--black1000);
			}

			border-bottom: var(--borderSize) solid var(--black1000);
		}
	}

	textarea {
		height: var(--textareaHeight);
		min-height: var(--textareaHeight);
		max-height: var(--textareaHeight);
	}

	.ant-input-suffix,
	.ant-input-textarea-suffix {
		display: none;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--inputPadTB: var(--sp2x);
		--borderSize: 2px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--inputPadTB: var(--sp2x);
		--borderSize: 1.7px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--inputPadTB: var(--sp2x);
		--borderSize: 1.7px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--inputPadTB: var(--sp2x);
		--borderSize: 1.7px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--inputPadTB: var(--sp1-5x);
		--borderSize: 1.5px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--inputPadTB: var(--sp1-5x);
		--borderSize: 1.5px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--inputPadTB: var(--sp1-5x);
		--borderSize: 1.5px;
	}
`;

export default FormInputStyle;
