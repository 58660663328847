//* Components
import { Checkbox } from 'antd';
import Icon from '@/components/common/Icon';
import Text from '@/components/common/Text';

//* Style
import CheckboxStyle from './style';

const CustomCheckbox = ({ checkboxText, onChange, accept = false }) => {
	return (
		<CheckboxStyle>
			<div className='checkbox-wrap'>
				<Checkbox
					defaultChecked={accept}
					onChange={onChange}
				/>

				{accept ? (
					<Icon
						name={'check'}
						className={'check-icon'}
					/>
				) : null}
			</div>

			<Text
				className={'p p1 font-montserrat main-blue checkbox-text'}
				text={checkboxText}
			/>
		</CheckboxStyle>
	);
};

export default CustomCheckbox;
