import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50%; /** //! Default size */

	&.imageCont {
		position: relative;
		width: 100%;
		padding-top: var(--proportion);

		img {
			object-fit: cover;
		}
	}
`;
export default ImageStyle;
