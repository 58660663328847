import styled from 'styled-components';

const CheckboxStyle = styled.div`
	--checkboxSizes: var(--sp5x);
	--checkIconSizes: var(--sp3x);
	--checkboxTextMarginLeft: var(--sp2x);
	--borderRadius: var(--sp1x);

	display: flex;
	align-items: center;

	.checkbox-wrap {
		display: block;
		position: relative;
		width: var(--checkboxSizes);
		height: var(--checkboxSizes);
	}

	.checkbox-text {
		margin-left: var(--checkboxTextMarginLeft);
	}

	.ant-checkbox-wrapper {
		display: block;
		height: var(--checkboxSizes);
		width: var(--checkboxSizes);

		.ant-checkbox {
			display: block;

			height: var(--checkboxSizes);
			width: var(--checkboxSizes);

			top: unset;
			input {
				cursor: pointer;

				position: absolute;
				inset: 0;
				height: var(--checkboxSizes);
				width: var(--checkboxSizes);
			}
			.ant-checkbox-inner {
				display: block;
				width: var(--checkboxSizes);
				height: var(--checkboxSizes);
				border: 3px solid var(--black1000);
				background-color: transparent;
				border-radius: var(--borderRadius);

				&::after {
					display: none;
				}
			}

			&.ant-checkbox-checked {
				.ant-checkbox-inner {
					background-color: var(--black1000);
				}
			}
		}
	}

	.check-icon {
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: var(--checkIconSizes);

		&::before {
			color: var(--white);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--checkboxSizes: var(--sp4x);
		--checkIconSizes: var(--sp2x);
		--checkboxTextMarginLeft: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--checkboxSizes: var(--sp4x);
		--checkIconSizes: var(--sp2x);
		--checkboxTextMarginLeft: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--checkboxSizes: var(--sp4x);
		--checkIconSizes: var(--sp2x);
		--checkboxTextMarginLeft: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--checkboxSizes: var(--sp4x);
		--checkIconSizes: var(--sp2x);
		--checkboxTextMarginLeft: var(--sp1-5x);
		--borderRadius: 6px;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--checkboxSizes: var(--sp3x);
		--checkIconSizes: var(--sp1-5x);
		--checkboxTextMarginLeft: var(--sp1-5x);
		--borderRadius: var(--sp0-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--checkboxSizes: var(--sp3x);
		--checkIconSizes: var(--sp1-5x);
		--checkboxTextMarginLeft: var(--sp1-5x);
		--borderRadius: var(--sp0-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--checkboxSizes: var(--sp3x);
		--checkIconSizes: var(--sp1-5x);
		--checkboxTextMarginLeft: var(--sp1-5x);
		--borderRadius: var(--sp0-5x);
	}
`;

export default CheckboxStyle;
