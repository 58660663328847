import { useState, useRef, useCallback } from 'react';

//* HOC's
import { withDataContext, withLanguageContext } from '@/context';

//* Style
import PopupRequestCallStyle from './style';

//* Components
import Text from '@/components/common/Text';
import FormContainer from '@/components/common/Form/FormContainer';
import FormItem from '@/components/common/Form/FormItem';
import FormInput from '@/components/common/Form/FormInput';
import Container from '@/components/common/Container';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';

const PopupRequestCall = ({ translate, contactUs }) => {
    //! Refs
    const form = useRef();

    //! States
    const [isSuccess, setIsSuccess] = useState(false);
    const [accept, setAccept] = useState(false);

    const handleAcceptChange = useCallback(
        (e) => {
            setAccept(e.target.checked);
        },
        [accept]
    );

    //! Handle Finish
    const onFinish = async (values) => {
        setIsSuccess('loading');

        return await contactUs({ ...values })
            .then(() => {
                setIsSuccess(true);

                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            })
            .catch((err) => {
                setIsSuccess(false);

                return Promise.reject(err);
            });
    };

    return (
        <PopupRequestCallStyle>
            <Text
                className={'h3 font-montserrat-medium title'}
                text={'requestACall'}
            />

            <FormContainer
                ref={form}
                onFinish={onFinish}>
                <Container
                    row
                    full>
                    <div className='col-6 col-m-12 everyInput'>
                        <FormItem name={'first_name'}>
                            <FormInput
                                uppercase
                                placeholder={translate('firstName')}
                            />
                        </FormItem>
                    </div>

                    <div className='col-6 col-m-12 everyInput'>
                        <FormItem name={'last_name'} required={false}>
                            <FormInput
                                uppercase
                                placeholder={translate('lastName')}
                            />
                        </FormItem>
                    </div>

                    <div className='col-6 col-m-12 everyInput'>
                        <FormItem
                            name={'email'}
                            required={false}
                            customMessage={true}
                            rules={[
                                { type: 'email', message: translate('invalidEmailAddress') }
                            ]}
                        >
                            <FormInput placeholder={translate('email')} />
                        </FormItem>
                    </div>

                    <div className='col-6 col-m-12 everyInput'>
                        <FormItem name={'phone'}>
                            <FormInput
                                isPhoneInput
                                placeholder={translate('phone')}
                            />
                        </FormItem>
                    </div>
                </Container>

                <Checkbox
                    accept={accept}
                    onChange={handleAcceptChange}
                    checkboxText={translate('checkboxText')}
                />

                <div className={`submit-and-success-wrap ${isSuccess ? 'success' : ''}`}>
                    {isSuccess && isSuccess !== 'loading' ? (
                        <Text
                            className={'h6 font-montserrat-bold main-green'}
                            text={'requestCallSuccessMessage'}
                        />
                    ) : (
                        <Button
                            type={'submit'}
                            colorType={'red'}
                            backgroundColor={'green'}
                            disabled={!accept || isSuccess}
                            className={'send-request-button'}
                            isDisabled={!accept || isSuccess}
                            text={isSuccess === 'loading' ? `${translate('loading')}...` : translate('send')}
                        />
                    )}
                </div>
            </FormContainer>
        </PopupRequestCallStyle>
    );
};

export default withDataContext(withLanguageContext(PopupRequestCall, ['translate']), ['contactUs']);
