import { forwardRef, useRef, useImperativeHandle } from 'react';
import Image from 'next/image';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, className, width, height, priority = true, onClick }, ref) => {
	const imgRef = useRef();

	useImperativeHandle(ref, () => imgRef.current, []);

	return (
		<ImageStyle
			style={{ width, height }}
			className={`imageCont ${className || ''}`}
			ref={imgRef}>
			<Image
				fill
				onClick={onClick}
				priority={priority}
				alt={alt || 'Image'}
				sizes='(min-width: 320px) 100%'
				src={src || '/images/default.jpeg'}
				loading={priority ? 'eager' : 'lazy'}
				// unoptimized={src && src.includes('.svg')}
				unoptimized={true}
			/>
		</ImageStyle>
	);
});

CustomImage.displayName = 'CustomImage';

export default CustomImage;
