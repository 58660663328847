import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Component's
import Icon from '@/components/common/Icon';
import CustomLink from '@/components/common/CustomLink';
import Text from '@/components/common/Text';

//* Style
import ButtonStyle from './style';
import { withUIContext } from '@/context';

const Button = forwardRef(({ btnType, text, icon = { className: '', name: 'arrow' }, isDisabled = false, className = '', onClick, children, url, customLinkProps, translate, target, type = 'button', colorType = 'white', isFullRadius, iconName, external = false, disabled = false, winWidth }, ref) => {
	//! Refs
	const layerRef = useRef();

	const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);
	const customProps = useMemo(() => (url ? { ...customLinkProps, url, target, external } : { type, disabled }), [url, target, external, type, disabled]);

	let button;
	//! isDesktop
	const isDesktop = winWidth >= 1280;

	//! Animation
	const mouseEnter = useCallback(() => {
		gsap.fromTo(layerRef.current, { x: '-100%' }, { x: '0%', duration: 0.5, ease: 'power3.easeOut' });
	}, []);

	const mouseLeave = useCallback(() => {
		gsap.fromTo(layerRef.current, { x: '0%' }, { x: '100%', duration: 0.5, ease: 'power3.easeOut' }).then(() => gsap.to(layerRef.current, { x: '-100%', duration: 0 }));
	}, []);

	switch (btnType) {
		case 'frame':
			button = (
				<ButtonStyle
					className={`${btnType} ${className} ${isDisabled ? 'disable' : ''} ${isFullRadius ? 'full-radius' : ''}`}
					onClick={onClick}
					onMouseEnter={isDesktop ? mouseEnter : null}
					onMouseLeave={isDesktop ? mouseLeave : null}>
					<button aria-label='file-arrow-icon'>
						<div
							className='layer'
							ref={layerRef}
						/>
						<Icon
							className={`arrow-icon ${icon.className}`}
							name={icon.name}
						/>
					</button>
				</ButtonStyle>
			);
			break;

		case 'back':
			button = (
				<ButtonStyle
					className={`${btnType} ${className} ${isDisabled ? 'disable' : ''}`}
					onClick={onClick}>
					<Component {...customProps}>
						<Icon
							className={`back-icon ${icon.className}`}
							name={icon.name}
						/>
						<Text className={'p p1 font-montserrat-medium inner-text'}>{children || translate(text)}</Text>
					</Component>
				</ButtonStyle>
			);
			break;

		default:
			button = (
				<ButtonStyle
					className={`default ${iconName ? 'with-icon' : ''} ${className} ${isDisabled ? 'disable' : ''} type-${colorType}`}
					onClick={onClick}
					onMouseEnter={isDesktop ? mouseEnter : null}
					onMouseLeave={isDesktop ? mouseLeave : null}>
					<Component {...customProps}>
						<div
							className='layer'
							ref={layerRef}
						/>
						<Text className={'h6 font-montserrat-medium inner-text'}>{children || translate(text)}</Text>
						{iconName && (
							<Icon
								className={'h3 button-icon'}
								name={iconName}
							/>
						)}
					</Component>
				</ButtonStyle>
			);
			break;
	}
	return button;
});

export default withUIContext(withLanguageContext(Button, ['translate']), ['winWidth']);
