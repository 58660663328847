import { forwardRef } from 'react';
import Link from 'next/link';

//* HOC's
import { withLanguageContext } from '@/context';

const CustomLink = forwardRef(({ url, className, prefetch = true, scroll = true, replace = false, selectedLang, external = false, ...props }, ref) => {
    return (
        <Link
            ref={ref}
            scroll={scroll}
            replace={replace}
            prefetch={prefetch}
            className={`link-item ${className || ''}`}
            rel={external ? 'noopener noreferrer' : ''}
            target={external ? '_blank' : props.target || '_self'}
            aria-label={props.ariaLabel || (typeof url == 'object' ? url.pathname : url)}
            href={typeof url === 'object' ? { ...url, pathname: external ? `${url.pathname}` : `/${selectedLang}${url.pathname}` } : external ? url : `/${selectedLang}${url}`}>
            {props.children || props.content}
        </Link>
    );
});

export default withLanguageContext(CustomLink, ['selectedLang']);