import styled from 'styled-components';

const FormItemStyle = styled.div`
	//! Error
	.ant-form-item-explain,
	.ant-form-item-extra {
		color: var(--errorColor);
		font-size: var(--p2);
		font-family: var(--montserrat);
		font-weight: 400;
		line-height: var(--lineHeightS);
		transform: translateY(calc(var(--p2) / 1.5));
		transition: color var(--trTime) cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	.ant-form-item-with-help .ant-form-item-explain {
		height: auto;
		opacity: 1;
	}

	.ant-show-help {
		transition: opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.ant-show-help-appear,
	.ant-show-help-enter {
		opacity: 0;
	}

	.ant-show-help-appear-active,
	.ant-show-help-enter-active {
		opacity: 1;
	}

	.ant-show-help-leave {
		opacity: 1;
	}

	.ant-show-help-leave-active {
		opacity: 0;
	}

	.ant-show-help-item {
		overflow: hidden;
		transition: height var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), opacity var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1), transform var(--trTime) cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}

	.ant-show-help-item-appear,
	.ant-show-help-item-enter {
		transform: translateY(-5px);
		opacity: 0;
	}

	.ant-show-help-item-appear-active,
	.ant-show-help-item-enter-active {
		transform: translateY(0);
		opacity: 1;
	}

	.ant-show-help-item-leave {
		transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	}

	.ant-show-help-item-leave-active {
		transform: translateY(-5px);
	}

	.ant-form-item-has-error {
		input,
		textarea,
		label,
		.ant-select-selection-placeholder,
		.ant-select-arrow {
			color: var(--errorColor) !important;
		}

		.ant-checkbox-wrapper-in-form-item {
			span {
				color: var(--errorColor) !important;
			}
			input {
				border-bottom: var(--borderSize) solid var(--errorColor) !important;
			}
		}

		input,
		textarea {
			border-bottom: var(--borderSize) solid var(--errorColor) !important;

			&::placeholder {
				color: var(--errorColor) !important;
			}
		}
	}
`;

export default FormItemStyle;
